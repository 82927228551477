import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import {
  Breadcrumbs, IconButton, Tooltip, Typography, makeStyles,
  Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
} from '@material-ui/core';
import CreateIcon from '@material-ui/icons/Create';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import PageLayout from '../../PageLayout';
import Button from '../../controls/Button';
import DataTable from '../../DataTable/DataTable';
import { history, workFieldHelper } from '../../../helpers';
import { providerActions } from '../../../redux/actions';
import { providersTexts } from '../../../constants';
import colors from '../../../assets/colors';
import CircleButton from '../../controls/CircleButton';
import PopoverWarningMessage from '../../controls/PopoverWarningMessage';
import CustomDialogAlert from '../../controls/CustomDialogAlert';

const useStyles = makeStyles(() => ({
  tableBody: {
    color: 'black',
    textDecoration: 'none',
    fontSize: '12px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
  },
}));

const ProviderPage = () => {
  const intl = useIntl();
  const classes = useStyles();
  const dispatch = useDispatch();
  const userAuth = useSelector((state) => state.authentication.user);
  const deletingProvider = useSelector((state) => state.provider.deleting);
  const deletedProvider = useSelector((state) => state.provider.deleted);
  const noLinkedWorkField = useSelector((state) => state.provider.noLinkedWorkField);
  const isAdmin = userAuth.roles.includes('Admin');
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [providerID, setProviderID] = useState(null);
  const invalidData = [null, undefined, NaN, ''];

  const [openNoLinkedWorkFieldDialog, setOpenNoLinkedWorkFieldDialog] = useState(false);
  const noLinkedWorkFieldMessage = {
    title: providersTexts.noLinkedWorkFieldTitle,
    body: providersTexts.noLinkedWorkFieldBody,
  };

  useEffect(() => {
    if (noLinkedWorkField) {
      setOpenNoLinkedWorkFieldDialog(true);
    }
  }, [noLinkedWorkField]);

  useEffect(() => {
    if (deletedProvider) {
      setOpenDeleteDialog(false);
    }
  }, [deletedProvider]);

  function toggleDeleteDialog() {
    setOpenDeleteDialog(!openDeleteDialog);
  }

  function handleDeleteProviders(id) {
    dispatch(providerActions.deleteProvider(id, providersTexts.successfulDelete));
  }

  function toggleNoLinkedWorkFieldDialog() {
    setOpenNoLinkedWorkFieldDialog(!openNoLinkedWorkFieldDialog);
  }

  function ProviderPageBreadcrumbs() {
    return (
      <Breadcrumbs separator="›" aria-label="breadcrumb">
        <Typography component={Link} to="/providers" variant="caption" color="textPrimary" style={{ fontWeight: 700, fontSize: 11, textDecoration: 'none' }}>
          {providersTexts.providers}
        </Typography>
      </Breadcrumbs>
    );
  }

  function ButtonGroup() {
    if (!isAdmin) return null;
    return (
      <>
        <Button
          text={providersTexts.addButton}
          component={Link}
          to="providers/new"
          style={{ fontSize: '11px', fontWeight: 600 }}
        />
        <CircleButton
          text={(
            <Tooltip title={providersTexts.bulkCreationTitle}>
              <GroupAddIcon color="primary" fontSize="small" />
            </Tooltip>
          )}
          component={Link}
          to="providers/upload"
          variant="outlined"
        />
      </>
    );
  }

  function actionsButtons(link, id) {
    if (!isAdmin) return null;
    return (
      <>
        <IconButton onClick={() => history.push(link)} style={{ textDecoration: 'none', padding: '0px', marginRight: '16px' }}>
          <Tooltip title={providersTexts.updateProvider}>
            <CreateIcon style={{ width: 16, height: 16, color: colors.orangeLight }} />
          </Tooltip>
        </IconButton>
        <IconButton onClick={() => { setProviderID(id); toggleDeleteDialog(); }} style={{ textDecoration: 'none', padding: '0px', marginRight: '16px' }}>
          <Tooltip title={providersTexts.deleteTitleDialog}>
            <DeleteRoundedIcon style={{ width: 16, height: 16, color: colors.greyLight }} />
          </Tooltip>
        </IconButton>
      </>
    );
  }

  function getWorkFieldsName(providerWorkField) {
    const entityLink = `/workFields/${providerWorkField.id}`;
    return (
      <Typography className={classes.tableBody} style={{ display: 'inline' }} component={Link} to={entityLink}>
        {providerWorkField.name}
      </Typography>
    );
  }

  const columns = [{
    name: 'id',
    options: {
      display: 'excluded',
      filter: false,
    },
  }, {
    name: 'name',
    label: intl.formatMessage({ id: 'app.name', defaultMessage: 'Nombre' }),
    options: {
      customBodyRender: (value, meta) => {
        const nameId = meta.rowData[0];
        const textTitle = (
          <Typography className={classes.tableBody} component={Link} to={`/providers/${nameId}`}>
            {value}
          </Typography>
        );
        return textTitle;
      },
    },
  }, {
    name: 'workField',
    label: intl.formatMessage({ id: 'providers.workFields', defaultMessage: 'Rubros' }),
    options: {
      customBodyRender: (value, meta) => {
        const id = meta.rowData[0];
        if (value === '-') {
          return (
            <PopoverWarningMessage
              id={id}
              message={providersTexts.noLinkedWorkFielPopoverMessage}
              customStyle={{ maxWidth: '350px' }}
            />
          );
        }
        return getWorkFieldsName(value);
      },
      filter: false,
      customDownloadBodyRender: (value) => value.map((workField) => workField.workField.name).join(', '),
    },
  }, {
    name: 'cuit',
    label: intl.formatMessage({ id: 'providers.cuit', defaultMessage: 'CUIT' }),
  }, {
    name: 'businessName',
    label: intl.formatMessage({ id: 'providers.businessName', defaultMessage: 'Razón Social' }),
  }, {
    name: 'lastWorkFieldEvaluation',
    label: intl.formatMessage({ id: 'providers.lastWorkFieldEvaluations', defaultMessage: 'Resultado última evaluación' }),
    options: {
      customBodyRender: (value) => {
        if (value && value !== '-' && !invalidData.includes(value.evaluationStatus)) {
          return workFieldHelper.getEvaluationStatusLabel(value.evaluationStatus);
        }
        return '-';
      },
    },
  }, {
    name: 'lastWorkFieldEvaluation',
    label: intl.formatMessage({ id: 'workFieldEvaluation.date', defaultMessage: 'Fecha de evaluación' }),
    options: {
      customBodyRender: (value) => {
        if (value && value !== '-' && value.date) {
          return moment(value.date).format('DD/MM/YYYY');
        }
        return '-';
      },
    },
  }, {
    name: '',
    options: {
      filter: false,
      sort: false,
      download: false,
      customBodyRender: (value, meta) => {
        const providerId = meta.rowData[0];
        const link = `/providers/${providerId}/edit`;
        return actionsButtons(link, providerId);
      },
    },
  }];

  const keys = ['id', 'name', 'workField', 'cuit', 'businessName', 'lastWorkFieldEvaluation', 'lastWorkFieldEvaluation'];
  const fetchUrl = `companies/${userAuth.company.id}/providers/byWorkFields`;
  const itemUrl = 'providers/';

  return (
    <PageLayout
      title={providersTexts.providers}
      breadCrumbs={<ProviderPageBreadcrumbs />}
      buttonGroup={<ButtonGroup />}
    >
      <DataTable
        title={intl.formatMessage({ id: 'providers.providers', defaultMessage: 'Proveedores' })}
        fetchUrl={fetchUrl}
        itemUrl={itemUrl}
        keys={keys}
        columns={columns}
      />
      <Dialog open={openDeleteDialog} onClose={() => toggleDeleteDialog()} fullWidth maxWidth="xs" aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">{providersTexts.deleteTitleDialog}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {providersTexts.deleteContentDialog}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button text={providersTexts.cancel} onClick={() => toggleDeleteDialog()} />
          <Button
            text={providersTexts.delete}
            color="secondary"
            loading={deletingProvider}
            onClick={() => { handleDeleteProviders(providerID); }}
          />
        </DialogActions>
      </Dialog>
      <CustomDialogAlert
        toggleDialog={toggleNoLinkedWorkFieldDialog}
        openDialog={openNoLinkedWorkFieldDialog}
        message={noLinkedWorkFieldMessage}
      />

    </PageLayout>
  );
};

export default ProviderPage;
